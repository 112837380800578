import api from '.';
import { TSUser } from '../types/types';

const findAllTSUser = () => api.get('/tsusers');

const getIsTS = () => api.get('/tsusers/isTS');

const createTSUser= (tsUser: TSUser) =>
  api.post(`/tsusers/`, tsUser);

const updateTSUser = (tsUserId: string, udatedTSUSer: TSUser) =>
  api.put(`/tsusers/${tsUserId}`, udatedTSUSer);

const deleteTSUserById = (tsUserId: string) =>
  api.delete(`/tsusers/${tsUserId}`);

const findAllRecommendations = () => api.get('/recommendations');

const getRecommendationsForEvent = (eventId: string) =>
  api.get(`/recommendations/event/${eventId}`);

const getNumberOfRecommendationsByUserForAnEvent = (eventId: string) =>
  api.get(`/recommendations/event/${eventId}/mine/count`);

const getUserEventRecommendations = (eventId: string) =>
  api.get(`/recommendations/event/${eventId}/mine`);

const addRecommendation = (recommendation: any) =>
  api.post(`/recommendations/`, recommendation);

const updateRecommendation = (recommendationId: string, recommendation: any) =>
  api.put(`/recommendations/${recommendationId}`, recommendation);

const deleteRecommendation = (recommendationId: string) =>
  api.put(`/recommendations/${recommendationId}/archive`);

const recommendationsByTime = () => api.get('/insights/recommendations');

const findAllEvents = () => api.get('/events');

const findEventById = (eventId: string) => api.get(`/events/${eventId}`);

const findAllUnarchivedEvents = () => api.get('/events?archived=0');

const findUpcomingEvent = () => api.get('/events/upcoming/next');

const createEvent = (event: any) => api.post('/events', event);

const updateEvent = (eventId: string, updatedEvent: any) =>
  api.put(`/events/${eventId}`, updatedEvent);

const archiveEvent = (eventId: string) =>
  api.patch(`/events/${eventId}`, [
    { op: 'add', path: '/archived', value: 'true' },
  ]);

const hasParticipantJoinedEvent = () =>
  api.get(`/eventsparticipants/hasJoined/`);

const addUserToEvent = (eventId: string) =>
  api.post(`/eventsparticipants/join/${eventId}`);

const removeUserFromEvent = () => api.post(`/eventsparticipants/leave`);

export {
  addRecommendation,
  addUserToEvent,
  archiveEvent,
  createTSUser,
  createEvent,
  updateTSUser,
  deleteTSUserById,
  deleteRecommendation,
  findAllEvents,
  findEventById,
  findAllRecommendations,
  findAllUnarchivedEvents,
  findAllTSUser,
  getIsTS,
  getNumberOfRecommendationsByUserForAnEvent,
  hasParticipantJoinedEvent,
  recommendationsByTime,
  removeUserFromEvent,
  updateEvent,
  updateRecommendation,
  findUpcomingEvent,
  getUserEventRecommendations,
  getRecommendationsForEvent,
};
